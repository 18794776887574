import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InvestigacionBuscarPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InvestigacionPage",
            ss: ss,
            ajax: {
                "url": ss.indexInvestigacionBuscar(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona', orderable: false, searchable: false},
                { data: 'Carrera', name: 'Carrera', title: 'Carrera' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                { data: 'Estado', name: 'Estado', title: 'Estado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            investigacions: [],
            investigacion: {},
            errorBag: {},
            lineaInvestigacions: [],
            areaInvestigacions: [],
            centroInvestigacions: [],
            carreras: [],
            unidadAcademicas: [],
            estados: [],
            options: [],
            Programa: {},
            actividad:{},
            objetivo: {},
            presupuesto: {},
            financiamiento: {},
            bibliografia:{},
            investigacionPersona:{},
            TipoInvestigacion: {},
            isLoadingFile:false,
            tablaActividad: {},
            personas:[],
            query:'',
            selectedPersona:{},
            personasSearch: '',
            users: [],
            material: {},

            persona: {},
            seccion: {},
            actividadFields: ['Actividad', 'Descripcion', 'Porcentaje', 'Acciones'],
            objetivoFields: ['Objetivo', 'Descripcion', 'Acciones'],
            materialFields: ['Num', 'Material', 'Laboratorio'],
            evaluacionPropuestaFields: ['EvaluacionPropuesta', 'FechaRevision', 'Nota', 'Acciones'],
            investigacionPersonaFields: ['Persona','CI', 'Celular', 'Acciones'],
            bibliografiaFields: ['Bibliografia','ApellidoAutor', 'NombreAutor', 'Paginas', 'Acciones'],
            presupuestoFields: ['Num','PartidaGasto', 'A1', 'A2', 'Acciones'],
            financiamientoFields: ['Num','FuenteFinanciamiento', 'Monto'],
            listaObjetivos: {},
            evaluacionPropuestas:[],
            evaluacionPropuestaLabels5: [
                '1',
                '2',
                '3',
                '4',
                '5',
            ],
            evaluacionPropuesta: {},
            // evaluacionPropuestaLabels5: [
            //     '1 No contempla',
            //     '2 No menciona ni explica',
            //     '3 No es claro',
            //     '4 Es ambiguo',
            //     '5 Menciona y explica',
            // ],
            evaluacion1: 1,
            filtros: {}
            
        };
    },
    methods: {

        aplicarFiltros(){
            let filtrosLocal = this.filtros;
            this.ajax.data = function (d) {
                 d.UnidadAcademica = filtrosLocal.UnidadAcademica;
                 d.Carrera = filtrosLocal.Carrera;
                 d.AreaInvestigacion = filtrosLocal.AreaInvestigacion;
                 d.LineaInvestigacion = filtrosLocal.LineaInvestigacion;
                 d.TipoInvestigacion = filtrosLocal.TipoInvestigacion;
            }
            this.$refs['datatable-investigacion'].reloadAjax(this.ajax);
        },
        lineasInvFiltros(){
            this.getLineaInvestigacion();
            this.aplicarFiltros();
        },

        showInvestigacion(id) {
            <div v-if="auth.Rol == 1" class="active"></div>
            this.ss.showInvestigacion(id).then(
                (result) => {
                    let response = result.data;
                    this.investigacion = response.data;
                    this.$refs['view-investigacion'].show();
                    //recuperar los objetivos para dibujar el cronograma
                    this.listObjetivos();
                    this.listEvaluacionPropuesta();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        cancelInvestigacion() {
            if (this.investigacion.id) {
                this.$refs['view-investigacion'].show();
            }
            this.$refs['frm-investigacion'].hide();
        },
        
        draw() {
            window.$('.btn-datatable-Investigacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInvestigacion(data);
            });
        },
        // Objetivos
        showObjetivo(id) {
            this.isLoading = true;
            this.ss.showObjetivo(id).then(
                (result) => {
                    let response = result.data;
                    this.objetivo = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newObjetivo() {
            this.objetivo = {};
            this.$refs['frm-objetivo'].show();
        },
        saveObjetivo() {
            this.objetivo.Investigacion = this.investigacion.id;
            this.ss.storeObjetivo(this.objetivo).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-objetivo'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editObjetivo(objetivo) {
            this.$refs['frm-objetivo'].show();
            this.showObjetivo(objetivo.id);
        },
        deleteObjetivo(objetivo) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyObjetivo(objetivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelObjetivo() { 
            this.$refs['frm-objetivo'].hide();
        },
        // Presupuesto
        showPresupuesto(id) {
            this.isLoading = true;
            this.ss.showPresupuesto(id).then(
                (result) => {
                    let response = result.data;
                    this.presupuesto = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newPresupuesto() {
            this.presupuesto = {};
            this.$refs['frm-presupuesto'].show();
        },
        savePresupuesto() {
            this.presupuesto.Investigacion = this.investigacion.id;
            this.ss.storePresupuesto(this.presupuesto).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-presupuesto'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editPresupuesto(presupuesto) {
            this.$refs['frm-presupuesto'].show();
            this.showPresupuesto(presupuesto.id);
        },
        deletePresupuesto(presupuesto) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPresupuesto(presupuesto)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelPresupuesto() { 
            this.$refs['frm-presupuesto'].hide();
        },
        // Financiamiento
        showFinanciamiento(id) {
            this.isLoading = true;
            this.ss.showFinanciamiento(id).then(
                (result) => {
                    let response = result.data;
                    this.financiamiento = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newFinanciamiento() {
            this.financiamiento = {};
            this.$refs['frm-financiamiento'].show();
        },
        saveFinanciamiento() {
            this.financiamiento.Investigacion = this.investigacion.id;
            this.ss.storeFinanciamiento(this.financiamiento).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-financiamiento'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editFinanciamiento(financiamiento) {
            this.$refs['frm-financiamiento'].show();
            this.showMaterial(financiamiento.id);
        },
        deleteFinanciamiento(financiamiento) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyFinanciamiento(financiamiento)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelFinanciamiento() { 
            this.$refs['frm-financiamiento'].hide();
        },
        //Actividades
        showActividad(id) {
            this.isLoading = true;
            this.ss.showActividad(id).then(
                (result) => {
                    let response = result.data;
                    this.actividad = response.data;
                    this.isLoading = false;
                    this.llenarTabla();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newActividad() {
            if (this.objetivo.id) {
                this.actividad = {};
                this.$refs['frm-actividad'].show();
            }else{
                this.$bvToast.toast(
                    'Seleccione un Objetivo para agregar Actividades',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            }
        },
        saveActividad() {
            this.leerTabla();
            this.actividad.Objetivo = this.objetivo.id;
            this.ss.storeActividad(this.actividad).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-actividad'].hide();
                    this.showObjetivo(this.objetivo.id);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editActividad(actividad) {
            
            this.$refs['frm-actividad'].show();
            this.showActividad(actividad.id);
        },
        cancelActividad() {
            this.$refs['frm-actividad'].hide();
        },
        deleteActividad(actividad) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyActividad(actividad)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.showObjetivo(this.objetivo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        // Investigacion Persona
        showInvestigacionPersona(id) {
            this.isLoading = true;
            this.ss.showInvestigacionPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.investigacionPersona = response.data;
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newInvestigacionPersona() {
            this.investigacionPersona = {};
            this.$refs['frm-investigacionPersona'].show();
        },
        saveInvestigacionPersona() {
            this.investigacionPersona.Investigacion = this.investigacion.id;
            this.ss.storeInvestigacionPersona(this.investigacionPersona).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-investigacionPersona'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editInvestigacionPersona(investigacionPersona) {
            this.$refs['frm-investigacionPersona'].show();
            this.showInvestigacionPersona(investigacionPersona.id);
        },
        deleteInvestigacionPersona(investigacionPersona) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyInvestigacionPersona(investigacionPersona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelInvestigacionPersona() {
            this.$refs['frm-investigacionPersona'].hide();
        },
        // Material
        showMaterial(id) {
            this.isLoading = true;
            this.ss.showMaterial(id).then(
                (result) => {
                    let response = result.data;
                    this.material = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newMaterial() {
            this.material = {};
            this.$refs['frm-material'].show();
        },
        saveMaterial() {
            this.material.Investigacion = this.investigacion.id;
            this.ss.storeMaterial(this.material).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-material'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editMaterial(material) {
            this.$refs['frm-material'].show();
            this.showMaterial(material.id);
        },
        deleteMaterial(material) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyMaterial(material)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelMaterial() { 
            this.$refs['frm-material'].hide();
        },
        // Bibliografia
        showBibliografia(id) {
            this.isLoading = true;
            this.ss.showBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    this.bibliografia = response.data;
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newBibliografia() {
            this.bibliografia = {};
            this.$refs['frm-bibliografia'].show();
        },
        saveBibliografia() {
            this.bibliografia.Investigacion = this.investigacion.id;
            this.ss.storeBibliografia(this.bibliografia).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-bibliografia'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editBibliografia(bibliografia) {
            this.$refs['frm-bibliografia'].show();
            this.showInvestigacionPersona(bibliografia.id);
        },
        deleteBibliografia(bibliografia) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyBibliografia(bibliografia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelBibliografia() {
            this.$refs['frm-bibliografia'].hide();
        },

        // Evaluacion Propuesta
        listEvaluacionPropuesta(){
            let params = {
                Investigacion: this.investigacion.id
            }
            this.ss.listEvaluacionPropuesta(params).then(
                (result) => {
                    let response = result.data;
                    this.evaluacionPropuestas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showEvaluacionPropuesta(id) {
            this.isLoading = true;
            this.ss.showEvaluacionPropuesta(id).then(
                (result) => {
                    let response = result.data;
                    this.evaluacionPropuesta = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        newEvaluacionPropuesta(){
            this.$refs['frm-evaluacionpropuesta'].show();
        },

        saveEvaluacionPropuesta(){
            this.evaluacionPropuesta.Investigacion = this.investigacion.id;
            this.ss.storeEvaluacionPropuesta(this.evaluacionPropuesta).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.listEvaluacionPropuesta();

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-evaluacionpropuesta'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        editEvaluacionPropuesta(evaluacionPropuesta){
            this.$refs['frm-evaluacionpropuesta'].show();
            this.showEvaluacionPropuesta(evaluacionPropuesta.id);
        },

        deleteEvaluacionPropuesta(evaluacionPropuesta){
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyEvaluacionPropuesta(evaluacionPropuesta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                //this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        cancelEvaluacionPropuesta(){
            this.$refs['frm-evaluacionpropuesta'].hide();
        },

        //
        getLineaInvestigacion(){
            let params = {
                AreaInvestigacion: this.filtros.AreaInvestigacion
            }
            this.ss.listLineaInvestigacion(params).then(
                (result) => {
                    let response = result.data;
                    this.lineaInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAreaInvestigacion(){
            this.ss.listAreaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.areaInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCentroInvestigacion(){
            this.ss.listCentroInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.centroInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCarrera(){
            this.ss.listCarrera().then(
                (result) => {
                    let response = result.data;
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica(){
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPrograma(){
            return this.ss.listPrograma()
            .then(result =>{
                if(result.data.success){
                    this.Programa = result.data.data;
                }else{
                    console.log("ocurrio un error");
                }
            })
            .catch(error =>{
                console.log(error);
            })
        },
        getTipoInvestigacion(){
            return this.ss.listTipoInvestigacion()
            .then(result =>{
                if(result.data.success){
                    this.TipoInvestigacion = result.data.data;
                }else{
                    console.log("ocurrio un error");
                }
            })
            .catch(error =>{
                console.log(error);
            })
        },

        loadFileInvestigacion() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.investigacion.ArchivoInvestigacion = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getEstado(){
            this.ss.listEstado().then(
                (result) => {
                    let response = result.data;
                    this.estados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        buscarPersona(search, loading){
            loading(true);
            this.ss.select2Persona(search).then((res) => {

                this.options = res.data.items;
                loading(false);
            })
        },
        actualizarElaboradoPersona(value){
            this.investigacion.ElaboradoPersona = value.id;
        },
        actualizarRevisadoPersona(value){
            this.investigacion.RevisadoPersona = value.id;
        },
        actualizarAprobadoPersona(value){
            this.investigacion.AprobadoPersona = value.id;
        },
        actualizarVistoBuenoPersona(value){
            this.investigacion.VistoBuenoPersona = value.id;
        },
        leerTabla(){
            let totalPorcentaje = 0;
            let valores = [];
            console.log("ejecuto");
            let inputs = document.getElementsByClassName('input_tabla');
            let checks = document.getElementsByClassName('checkbox_tabla');
            for(let i = 0; i<inputs.length; i++){
                if(checks[i].checked){
                    //valores[i+1] = inputs[i].value;
                    totalPorcentaje = totalPorcentaje +parseInt(inputs[i].value);
                }
                valores.push({mes: i+1, valor: checks[i].checked, porcentaje: inputs[i].value});
                console.log(i+1, inputs[i].value, checks[i].checked);
            }
            if(totalPorcentaje != 100){
                this.$swal({
                    title: "La planificacion de la actividad debe sumar 100% ",
                    text: "La suma del porcentaje es: "+totalPorcentaje,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return false;
            }
            console.log(totalPorcentaje);
            //if($totalPorcentaje != 100)

            this.tablaActividad = valores;
            this.actividad.Planificacion = JSON.stringify(valores);
            return true;
        },

        llenarTabla(){
            console.log("Llenar");
            let checks = document.getElementsByClassName('checkbox_tabla');
            let inputs = document.getElementsByClassName('input_tabla');

            for (let value of this.actividad.planificacionObj) {
                checks[value.mes-1].checked = value.valor;
                inputs[value.mes-1].style.display = (value.valor ? "block" : "none");
                inputs[value.mes-1].value=value.porcentaje;
                console.log(value);
            }
            // let planificacion = JSON.parse(this.actividad.Planificacion);
            // for (var [key, value] of Object.entries(planificacion)) {
            //     //checks[key-1].checked = true;
            //     checks[key-1].checked = value.valor;
            //     inputs[key-1].style.display = (value.valor ? "block" : "none");
            //     inputs[key-1].value=value.porcentaje;
            //     // inputs[key-1].value=value;
            //     //inputs[key-1].value=value.porcentaje;
            //     // console.log('Esta es la llave: '+key + ', este es el valor: ' + value); // "a 5", "b 7", "c 9"
            //     console.log("Holaa", key, value);
            // }
            


            // console.log("Llenar");
            // let checks = document.getElementsByClassName('checkbox_tabla');
            // let inputs = document.getElementsByClassName('input_tabla');
            // //let planificacion = JSON.parse(this.actividad.Planificacion);
            // for (var [key, value] of this.actividad.planificacionObj) {
            //     checks[key-1].checked = value.valor;
            //     inputs[key-1].style.display = (value.valor ? "block" : "none");
            //     inputs[key-1].value=value.porcentaje;
            //     console.log('Esta es la llave: '+key + ', este es el valor: ' + value); // "a 5", "b 7", "c 9"
            // }

        },

        toggleInput(num){
            
            let checkeado = false;
            let inputnum = {};
            let checks = document.getElementsByClassName('checkbox_tabla');
            let inputs = document.getElementsByClassName('input_tabla');
            for(let i = 0; i<inputs.length; i++){
                //console.log(checks[i].getAttribute('num'));
                if(checks[i].getAttribute('num') == num){
                    checkeado = checks[i].checked;
                }
                if(inputs[i].getAttribute('num') == num){
                    inputnum = inputs[i];
                    if(checkeado == false){
                        inputnum.value = '';
                        inputnum.style.display = 'none';
                    }else{
                        inputnum.style.display = 'block';
                    }
                    
                    //break;
                }
            }
            console.log(checkeado, inputnum);
        },
        listObjetivos(){
            
            let params = {
                Investigacion: this.investigacion.id
            }
            this.ss.listObjetivo(params).then(
                (result) => {
                    let response = result.data;
                    this.listaObjetivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        reporteInvestigacion(){
            console.log("Generar reporte");
            
            this.ss.reporteInvestigacion(this.investigacion.id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    //this.forceFileDownload(response.data.url);
                    window.open(response.data.url);
                    
                }
            ).catch(error => {
                console.log(error);
                
            });
        },

        forceFileDownload(urlParam){
            const url = window.URL.createObjectURL(new Blob([urlParam]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'archivo.pdf') //or any other extension
            document.body.appendChild(link)
            link.click()
        },
        
    },
    watch: {
        query(q) {
            this.ss.select2Persona(q).then((res) => {
                this.users = res.data.items;
              })
        },

        queryInvestigacion(q) {
            this.ss.select2Investigacion(q).then((res) => {
                this.investigaciones = res.data.items;
              })
        },
        
    },
    components: {
        dataTable
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
        if (!this.persona) {
            this.$router.push('/Login');
        } else{
        this.getAreaInvestigacion();
        this.getLineaInvestigacion();
        this.getTipoInvestigacion();
        this.getCentroInvestigacion();
        this.getCarrera();
        this.getPrograma();
        this.getUnidadAcademica();
        this.getTipoInvestigacion();
        this.getEstado();
    }
  }
};

