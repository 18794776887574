import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");
//import _ from 'underscore'

export default {
    name: "InvestigacionPersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InvestigacionPersonaPage",
            ss: ss,
            ajax: {
                "url": ss.indexInvestigacionPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Investigacion', name: 'Investigacion', title: 'Investigación', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona', orderable: false, searchable: false},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            investigacionPersonas: [],
            investigacionPersona: {},
            errorBag: {},

            personas:[],
            query:'',
            selectedPersona:{},
            personasSearch: '',
            users: [],

            investigacions: [],
            queryInvestigacion: '',
            selectedInvestigacion: {},
            investigacionsSearch: '',

        };
    },
    methods: {    
        newInvestigacionPersona() {
            this.investigacionPersona = {};
            this.$refs['frm-investigacionPersona'].show();
        },
        showInvestigacionPersona(id) {
            this.ss.showInvestigacionPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.investigacionPersona = response.data;
                    this.$refs['view-investigacionPersona'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        editInvestigacionPersona() {
            this.query = "Juan Sebastian villa";
            this.$refs['frm-investigacionPersona'].show();
            this.$refs['view-investigacionPersona'].hide();
        },
        cancelInvestigacionPersona() {
            if (this.investigacionPersona.id) {
                this.$refs['view-investigacionPersona'].show();
            }
            this.$refs['frm-investigacionPersona'].hide();
        },
        saveInvestigacionPersona() {
            this.ss.storeInvestigacionPersona(this.investigacionPersona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-investigacionPersona'].hide();
                    this.$refs['datatable-investigacionPersona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteInvestigacionPersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyInvestigacionPersona(this.investigacionPersona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacionPersona'].hide();
                                this.$refs['datatable-investigacionPersona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-InvestigacionPersona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInvestigacionPersona(data);
            });
        }
    },
    watch: {
        query(q) {
            this.ss.select2Persona(q).then((res) => {
                this.users = res.data.items;
              })
        },

        queryInvestigacion(q) {
            this.ss.select2Investigacion(q).then((res) => {
                this.investigacions = res.data.items;
              })
        },
        
    },
    components: {
        dataTable
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
  }
};
