import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InvestigacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InvestigacionPage",
            ss: ss,
            ajax: {
                "url": ss.indexInvestigacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona', orderable: false, searchable: false},
                { data: 'Carrera', name: 'Carrera', title: 'Carrera' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                { data: 'Estado', name: 'Estado', title: 'Estado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            urlBackend: process.env.VUE_APP_MAIN_SERVICE,
            investigacions: [],
            investigacion: {},
            errorBag: {},
            lineaInvestigacions: [],
            areaInvestigacions: [],
            centroInvestigacions: [],
            carreras: [],
            unidadAcademicas: [],
            unidadInvestigacions: [],
            estados: [],
            options: [],
            Programa: {},
            actividad:{},
            objetivo: {},
            presupuesto: {},
            financiamiento: {},
            bibliografia:{},
            informeMensual:{},
            informesMensuales: [],
            informeMensualEvaluacion: {},
            informeMensualEvaluaciones: [],
            verInformeMensualEvaluacion: false,
            investigacionPersona:{},
            TipoInvestigacion: {},
            isLoadingFile:false,
            tablaActividad: {},
            personas:[],
            query:'',
            selectedPersona:{},
            personasSearch: '',
            users: [],
            material: {},

            actividadesMes: [],

            persona: {},
            seccion: {},
            actividadFields: ['Actividad', 'Porcentaje', 'Acciones'],
            objetivoFields: ['Objetivo', 'Porcentaje', 'Acciones'],
            materialFields: ['Num', 'Material', 'Laboratorio'],
            evaluacionPropuestaFields: ['FechaRevision', 'Nota', 'Resultado', 'Evaluador', 'Cargo', 'Acciones'],
            investigacionPersonaFields: ['Persona','CI', 'Celular', 'Acciones'],
            bibliografiaFields: ['Bibliografia', 'Acciones'],
            presupuestoFields: ['Num','PartidaGasto', 'A1', 'A2', 'Acciones'],
            financiamientoFields: ['Num','FuenteFinanciamiento', 'Monto'],
            informeMensualFields: ['Mes', 'Estado', 'UsuarioInforme', 'FechaInforme','Acciones'],
            informeMensualEvaluacionFields: [ 'Estado', 'UsuarioEvaluador', 'CargoEvaluador', 'Acciones'],
            // evaluacionInformeMensualFields: ['Acciones'],
            // evaluacionFinalFields: ['Acciones', 'Estado', 'UsuarioInforme', 'FechaInforme', 'Nota', 'Resultado', 'FechaEvaluacion', 'UsuarioEvaluador', 'Cargo'],
            documentoFinalFields: ['Estado', 'UsuarioInforme', 'CargoUsuario', 'FechaInforme', 'Acciones'],
            documentoFinalEvaluacionFields: ['Nota', 'ResultadoEvaluacion', 'UsuarioEvaluador', 'CargoEvaluador', 'FechaEvaluacion', 'Acciones'],
            
            listaObjetivos: [],
            evaluacionPropuestas:[],
            evaluacionPropuesta: {},
            // evaluacionFinals: [],
            documentosFinales: [],
            // evaluacionFinal: {},
            documentoFinal: {},
            documentoFinalEvaluaciones: [],
            documentoFinalEvaluacion: {},
            mesesInvestigacion: [],
            evaluacion1: 1,
            totalEvaluacionPropuesta: {
                totalResumen: 25,
                totalAntecedentes: 25,
                totalProblema: 20,
                totalJustificacion: 30,
                totalObjetivos: 25,
                totalMetodologia: 30,
                totalResultados: 25,
                totalBibliografia: 15,
                totalRecursos: 20,
                totalPresupuesto: 10,
            },
            totalEvaluacionFinal: {
                totalIntroduccion: 25,
                totalAntecedentes: 25,
                totalProblema: 20,
                totalObjetivos: 25,
                totalJustificacion: 30,
                totalAlcance: 20,
                totalMarcoTeorico: 10,
                totalMetodologia: 30,
                totalDesarrollo: 15,
                totalConclusiones: 10,
                totalBibliografia: 20,
                totalAnexos: 10,
            },
            editarEvaluacionMensual: false,
            editarDocumentoFinal: false,

        };
    },
    methods: {
        newInvestigacion() {
            this.investigacion = {};
            this.$refs['frm-investigacion'].show();
        },
        showInvestigacion(id) {

            this.ss.showInvestigacion(id).then(
                (result) => {
                    let response = result.data;
                    this.investigacion = response.data;
                    this.$refs['view-investigacion'].show();
                    //recuperar los objetivos para dibujar el cronograma
                    this.mesesInvestigacion= []
                    this.listObjetivos();
                    this.listEvaluacionPropuesta();
                    this.listInformeMensual();
                    this.listDocumentoFinal();
                    //this.listEvaluacionFinal();
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },
        editInvestigacion() {
            this.$refs['frm-investigacion'].show();
            this.$refs['view-investigacion'].hide();
        },
        cancelInvestigacion() {
            if (this.investigacion.id) {
                this.$refs['view-investigacion'].show();
            }
            this.$refs['frm-investigacion'].hide();
        },
        saveInvestigacion() {
            this.ss.storeInvestigacion(this.investigacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-investigacion'].hide();
                    this.$refs['datatable-investigacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteInvestigacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        enviarPropuestaInvestigacion() {
            this.$swal({
                title: "Estas seguro que desea Enviar su propuesta?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.enviarPropuestaInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        vistoBuenoInvestigacion() {
            if(this.investigacion.Estado<=3){
                this.$bvToast.toast(
                    'La investigacion debe estar aprobada',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
                return true;
            }
            this.$swal({
                title: "Estas seguro que desea dar el visto Bueno?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.vistoBuenoInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        finalizarInvestigacion() {
            if(this.investigacion.Estado<=3){
                this.$bvToast.toast(
                    'La investigacion debe estar aprobada',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
                return true;
            }
            this.$swal({
                title: "Estas seguro que desea dar el visto Bueno?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.finalizarInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        revisarInvestigacion() {
            //apobado estado 4
            if(this.investigacion.Estado<=3){
                this.$bvToast.toast(
                    'La investigacion debe estar aprobada',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
                return true;
            }
            this.$swal({
                title: "Estas seguro que acepta la revision?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.revisarInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        revisarJCInvestigacion() {
            //apobado estado 4
            if(this.investigacion.Estado<=3){
                this.$bvToast.toast(
                    'La investigacion debe estar aprobada',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
                return true;
            }
            this.$swal({
                title: "Estas seguro que acepta la revision?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.revisarJCInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        verificarEvaluacionPropuestaInvestigacion(){
            let parametros = {
                "Investigacion":this.investigacion.id
            };
            this.ss.verificarEvaluacionPropuestaInvestigacion(parametros)
            .then((result) => {
                let response = result.data;
                console.log(response);
                if(response.data == true){
                    console.log("Se aprobo la investigacion");
                    this.$refs['frm-investigacionFecha'].show();
                }else{
                    console.log("Se reporbo la investigacion");
                    this.$swal({
                        title: "La propuesta de investigacion debe ser evaluada",
                        icon: "warning",
                        buttons: false,
                        dangerMode: true,
                    })
                }
                
                // this.$refs['datatable-investigacion'].reload();
            })
            .catch(error => {
                console.log(error);
            })
        },
        aprobarInvestigacion() {
            if(!this.investigacion.FechaInicio){
                this.errorBag.FechaInicio = "Debe seleccionar una Fecha de inicio de la Investigacion";
                return true;
            }
            this.$swal({
                title: "Estas seguro que desea aprobar la investigación?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                showCancelButton: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarInvestigacion(this.investigacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-investigacionFecha'].hide();
                                this.$refs['view-investigacion'].hide();
                                this.$refs['datatable-investigacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Investigacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInvestigacion(data);
            });
        },
        // Objetivos
        showObjetivo(id) {
            this.isLoading = true;
            this.ss.showObjetivo(id).then(
                (result) => {
                    let response = result.data;
                    this.objetivo = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newObjetivo() {
            this.objetivo = {};
            this.$refs['frm-objetivo'].show();
        },
        saveObjetivo() {
            this.objetivo.Investigacion = this.investigacion.id;
            this.ss.storeObjetivo(this.objetivo).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-objetivo'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editObjetivo(objetivo) {
            this.$refs['frm-objetivo'].show();
            this.showObjetivo(objetivo.id);
        },
        deleteObjetivo(objetivo) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyObjetivo(objetivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelObjetivo() { 
            this.$refs['frm-objetivo'].hide();
        },
        // Presupuesto
        showPresupuesto(id) {
            this.isLoading = true;
            this.ss.showPresupuesto(id).then(
                (result) => {
                    let response = result.data;
                    this.presupuesto = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newPresupuesto() {
            this.presupuesto = {};
            this.$refs['frm-presupuesto'].show();
        },
        savePresupuesto() {
            this.presupuesto.Investigacion = this.investigacion.id;
            this.ss.storePresupuesto(this.presupuesto).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-presupuesto'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editPresupuesto(presupuesto) {
            this.$refs['frm-presupuesto'].show();
            this.showPresupuesto(presupuesto.id);
        },
        deletePresupuesto(presupuesto) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPresupuesto(presupuesto)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelPresupuesto() { 
            this.$refs['frm-presupuesto'].hide();
        },
        // Financiamiento
        showFinanciamiento(id) {
            this.isLoading = true;
            this.ss.showFinanciamiento(id).then(
                (result) => {
                    let response = result.data;
                    this.financiamiento = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newFinanciamiento() {
            this.financiamiento = {};
            this.$refs['frm-financiamiento'].show();
        },
        saveFinanciamiento() {
            this.financiamiento.Investigacion = this.investigacion.id;
            this.ss.storeFinanciamiento(this.financiamiento).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-financiamiento'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editFinanciamiento(financiamiento) {
            this.$refs['frm-financiamiento'].show();
            this.showMaterial(financiamiento.id);
        },
        deleteFinanciamiento(financiamiento) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFinanciamiento(financiamiento)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelFinanciamiento() { 
            this.$refs['frm-financiamiento'].hide();
        },
        //InformeMensual
        newInformeMensual() {
            this.informeMensual = {};
            this.editarEvaluacionMensual = false;
            this.$refs['frm-informemensual'].show();
        },
        saveInformeMensual() {
            this.informeMensual.Investigacion = this.investigacion.id;
            this.informeMensual.ActividadesArray = JSON.stringify(this.actividadesMes);
            let TareasProgramadas = "";
            this.actividadesMes.forEach(element => {
                TareasProgramadas = TareasProgramadas+element.Actividad+"\n";
            });
            this.informeMensual.TareasProgramadas = TareasProgramadas;
            this.ss.storeInformeMensual(this.informeMensual).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.showInvestigacion(this.investigacion.id);
                    this.listInformeMensual();
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-informemensual'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        listInformeMensual(){
            let params = {
                Investigacion: this.investigacion.id
            }
            this.ss.listInformeMensual(params).then(
                (result) => {
                    let response = result.data;
                    this.informesMensuales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        // editInformeMensual(informemensual) {
        //     this.$refs['frm-informemensual'].show();
        //     this.showMaterial(informemensual.id);
        // },
        deleteInformeMensual(informemensual) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyInformeMensual(informemensual)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelInformeMensual() { 
            this.$refs['frm-informemensual'].hide();
        },

        modificarEstadoInformeMensual(){
            let params = {
                InformeMensual: this.informeMensual.id
            }
            this.ss.modificarEstadoInformeMensual(params).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.listInformeMensual();
                    //this.informemensual = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        verInformeMensual(id){
            this.editarEvaluacionMensual = true;
            this.ss.showInformeMensual(id).then(
                (result) => {
                    let response = result.data;
                    this.informeMensual = response.data;
                    this.actividadesMes = JSON.parse(this.informeMensual.ActividadesArray);
                    this.$refs['frm-informemensual'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        verEvaluacionesInformeMensual(idInformeMensual){
            this.informeMensual.id = idInformeMensual;
            let params = {
                InformeMensual: this.informeMensual.id
            }
            this.ss.listInformeMensualEvaluacion(params).then(
                (result) => {
                    let response = result.data;
                    this.informeMensualEvaluaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            console.log(idInformeMensual);
        },
        newInformeMensualEvaluacion(){
            if (this.informeMensual.id) {
                this.informeMensualEvaluacion = {};
                this.verInformeMensualEvaluacion = false;
                this.$refs['frm-InformeMensualEvaluacion'].show();
            }else{
                this.$bvToast.toast(
                    'Seleccione un Informe Mensual para agregar una evaluacion',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            }
        },

        showInformeMensualEvaluacion(id){
            // this.$refs['frm-InformeMensualEvaluacion'].show();
            this.verInformeMensualEvaluacion = true;
            console.log("Abrir informe mensual", id)
            this.ss.showInformeMensualEvaluacion(id).then(
                (result) => {
                    let response = result.data;
                    this.informeMensualEvaluacion = response.data;
                    this.$refs['frm-InformeMensualEvaluacion'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        storeInformeMensualEvaluacion(estado){

            this.informeMensualEvaluacion.Investigacion = this.investigacion.id;
            this.informeMensualEvaluacion.InformeMensual = this.informeMensual.id;
            this.informeMensualEvaluacion.Estado = estado;
            this.informeMensualEvaluacion.CargoEvaluador = this.persona.rol.Rol;


            this.ss.storeInformeMensualEvaluacion(this.informeMensualEvaluacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-InformeMensualEvaluacion'].hide();
                    //this.showObjetivo(this.objetivo.id);
                    this.verEvaluacionesInformeMensual(this.informeMensual.id);
                    this.modificarEstadoInformeMensual();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        //abrirRespaldoEvaluacionMensual
        descargarStorage(nombreArchivo){
            if(!nombreArchivo){
                this.$swal({
                    title: "No se cargo ningun archivo",
                    text: "No se puede descargar",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            var urlFile = this.urlBackend+"storage/documents/"+nombreArchivo;
            window.open(urlFile);
            // var a = document.createElement("a");
            // a.href = urlFile;
            // a.setAttribute("download", nuevoNombre);
            // a.click();
        },
        //Actividades
        showActividad(id) {
            this.isLoading = true;
            this.ss.showActividad(id).then(
                (result) => {
                    let response = result.data;
                    this.actividad = response.data;
                    this.isLoading = false;
                    this.llenarTabla();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newActividad() {
            if (this.objetivo.id) {
                this.actividad = {};
                this.$refs['frm-actividad'].show();
            }else{
                this.$bvToast.toast(
                    'Seleccione un Objetivo para agregar Actividades',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            }
        },
        saveActividad() {
            let res = this.leerTabla();
            if(!res){
                return false;
            }
            this.actividad.Objetivo = this.objetivo.id;
            this.ss.storeActividad(this.actividad).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-actividad'].hide();
                    let idObjetivo = this.objetivo.id;
                    this.listObjetivos();
                    this.showObjetivo(idObjetivo);

                    // this.listaObjetivos.forEach(elemetn => {
                    //     if(elemetn.id == this.ob)
                    // })
                    // this.objetivo
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editActividad(id) {
            
            this.$refs['frm-actividad'].show();
            this.showActividad(id);
        },
        cancelActividad() {
            this.$refs['frm-actividad'].hide();
        },
        deleteActividad(actividad) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyActividad(actividad)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.showObjetivo(this.objetivo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        actividadesPorMes() {
            let objeto = {
                "Investigacion": this.investigacion.id,
                "Mes": this.informeMensual.NumMes
            };

            this.ss.obtenerActividadesMes(objeto).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.actividadesMes = response.data;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;

                });
        },

        
        // Investigacion Persona
        showInvestigacionPersona(id) {
            this.isLoading = true;
            this.ss.showInvestigacionPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.investigacionPersona = response.data;
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newInvestigacionPersona() {
            this.investigacionPersona = {};
            this.$refs['frm-investigacionPersona'].show();
        },
        saveInvestigacionPersona() {
            this.investigacionPersona.Investigacion = this.investigacion.id;
            this.ss.storeInvestigacionPersona(this.investigacionPersona).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-investigacionPersona'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editInvestigacionPersona(investigacionPersona) {
            this.$refs['frm-investigacionPersona'].show();
            this.showInvestigacionPersona(investigacionPersona.id);
        },
        deleteInvestigacionPersona(investigacionPersona) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyInvestigacionPersona(investigacionPersona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelInvestigacionPersona() {
            this.$refs['frm-investigacionPersona'].hide();
        },
        // Material
        showMaterial(id) {
            this.isLoading = true;
            this.ss.showMaterial(id).then(
                (result) => {
                    let response = result.data;
                    this.material = response.data;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newMaterial() {
            this.material = {};
            this.$refs['frm-material'].show();
        },
        saveMaterial() {
            this.material.Investigacion = this.investigacion.id;
            this.ss.storeMaterial(this.material).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-material'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editMaterial(material) {
            this.$refs['frm-material'].show();
            this.showMaterial(material.id);
        },
        deleteMaterial(material) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyMaterial(material)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelMaterial() { 
            this.$refs['frm-material'].hide();
        },
        // Bibliografia
        showBibliografia(id) {
            this.isLoading = true;
            this.ss.showBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    this.bibliografia = response.data;
                    this.isLoading = false;
                    this.$refs['frm-bibliografia'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        newBibliografia() {
            this.bibliografia = {};
            this.$refs['frm-bibliografia'].show();
        },
        saveBibliografia() {
            this.bibliografia.Investigacion = this.investigacion.id;
            this.ss.storeBibliografia(this.bibliografia).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showInvestigacion(this.investigacion.id);

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-bibliografia'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editBibliografia(bibliografia) {
            this.$refs['frm-bibliografia'].show();
            this.showInvestigacionPersona(bibliografia.id);
        },
        deleteBibliografia(bibliografia) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    console.log("este es el delete", willDelete);
                    if (willDelete.isConfirmed) {
                        this.ss.destroyBibliografia(bibliografia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cancelBibliografia() {
            this.$refs['frm-bibliografia'].hide();
        },

        // Evaluacion Propuesta
        listEvaluacionPropuesta(){
            let params = {
                Investigacion: this.investigacion.id
            }
            this.ss.listEvaluacionPropuesta(params).then(
                (result) => {
                    let response = result.data;
                    this.evaluacionPropuestas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showEvaluacionPropuesta(id) {
            this.isLoading = true;
            
            this.ss.showEvaluacionPropuesta(id).then(
                (result) => {
                    let response = result.data;
                    let objEvalPropuesta = response.data;
                    for (var [key, value] of Object.entries(objEvalPropuesta)) {
                        console.log(key + ' ' + value); // "a 5", "b 7", "c 9"
                        if(value !== null){
                            objEvalPropuesta[key] = String(value);
                        }
                        
                    }
                    this.evaluacionPropuesta = objEvalPropuesta;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        newEvaluacionPropuesta(){
            this.evaluacionPropuesta = {};
            
            this.$refs['frm-evaluacionpropuesta'].show();
        },

        saveEvaluacionPropuesta(){
            if(this.totalPresupuesto < 2 ||
                this.totalRecursos < 4 ||
                this.totalBibliografia < 3 ||
                this.totalResultados < 5 ||
                this.totalMetodologia < 6 ||
                this.totalObjetivos < 5 ||
                this.totalJustificacion < 6 ||
                this.totalProblema < 4 ||
                this.totalAntecedentes < 5 ||
                this.totalResumen < 5 ){
                    this.$swal({
                        title: "Debe completar la evaluacion!",
                        text: "Le faltan campos por completar ",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                    console.log("Falta completar");
                    return;
                }
            this.evaluacionPropuesta.Investigacion = this.investigacion.id;
            this.evaluacionPropuesta.NotaEvaluacion = this.totalEvaluacionCien;
            this.evaluacionPropuesta.ResultadoEvaluacion = this.resultadoEvaluacion;
            this.evaluacionPropuesta.Estado = this.resultadoEvaluacionEstado;
            this.ss.storeEvaluacionPropuesta(this.evaluacionPropuesta).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.listEvaluacionPropuesta();

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.$refs['frm-evaluacionpropuesta'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        editEvaluacionPropuesta(evaluacionPropuesta){
            this.$refs['frm-evaluacionpropuesta'].show();
            this.showEvaluacionPropuesta(evaluacionPropuesta.id);
        },

        deleteEvaluacionPropuesta(evaluacionPropuesta){
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyEvaluacionPropuesta(evaluacionPropuesta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                //this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        cancelEvaluacionPropuesta(){
            this.$refs['frm-evaluacionpropuesta'].hide();
        },

        // Evaluacion Final
        listDocumentoFinal(){
            let params = {
                Investigacion: this.investigacion.id
            }
            this.ss.listDocumentoFinal(params).then(
                (result) => {
                    let response = result.data;
                    this.documentosFinales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        listDocumentoFinalEvaluacion(item){
            let objetoEvaluacionFinal = { ...item};
            this.documentoFinal = objetoEvaluacionFinal;
            let params = {
                Investigacion: this.investigacion.id,
                DocumentoFinal: this.documentoFinal.id
            }
            console.log(params);
            this.ss.listDocumentoFinalEvaluacion(params).then(
                (result) => {
                    let response = result.data;
                    this.documentoFinalEvaluaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showEvaluacionFinal(id) {
            this.isLoading = true;
            
            this.ss.showEvaluacionFinal(id).then(
                (result) => {
                    let response = result.data;
                    let objEvalFinal = response.data;
                    for (var [key, value] of Object.entries(objEvalFinal)) {
                        console.log(key + ' ' + value); // "a 5", "b 7", "c 9"
                        if(value !== null){
                            objEvalFinal[key] = String(value);
                        }
                        
                    }
                    this.documentoFinal = objEvalFinal;
                    //this.$refs['view-curso'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        newDocumentoFinal(){
            this.documentoFinal = {};
            this.$refs['frm-documentofinal'].show();
        },

        modificarEstadoDocumentoFinal(){
            let params = {
                DocumentoFinal: this.documentoFinal.id
            }
            this.ss.modificarEstadoDocumentoFinal(params).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    let docFin = this.documentoFinal;
                    this.listDocumentoFinal();
                    this.listDocumentoFinalEvaluacion(docFin);

                    //this.informemensual = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newDocumentoFinalEvaluacion(){
            //this.showEvaluacionFinal(id);
            //this.documentoFinal = item;
            //this.editarDocumentoFinal = false;
            if (this.documentoFinal.id) {
                this.documentoFinalEvaluacion = {};
                this.$refs['frm-evaluacionfinal'].show();
            }else{
                this.$bvToast.toast(
                    'Seleccione un documento final para agregar una evaluacion',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            }
        },

        verEvaluacionFinal(item){
            //this.showEvaluacionFinal(id);
            let objetoEvaluacionFinal = { ...item};
            for (var [key, value] of Object.entries(objetoEvaluacionFinal)) {
                console.log(key + ' ' + value); // "a 5", "b 7", "c 9"
                if(value !== null){
                    objetoEvaluacionFinal[key] = String(value);
                }
            }
            this.documentoFinalEvaluacion = objetoEvaluacionFinal;
            this.editarDocumentoFinal = true;
            this.$refs['frm-evaluacionfinal'].show();
        },

        confirmarDocumentoFinal(){
            this.$swal({
                title: "Estas seguro de registrar este documento?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.saveDocumentoFinal();
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        saveDocumentoFinal(){
            this.documentoFinal.Investigacion = this.investigacion.id;
            // this.evaluacionPropuesta.NotaEvaluacion = this.totalEvaluacionCien;
            // this.evaluacionPropuesta.ResultadoEvaluacion = this.resultadoEvaluacion;
            this.ss.storeDocumentoFinal(this.documentoFinal).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.listEvaluacionFinal();
                    this.listDocumentoFinal();

                    //this.$refs['frm-evaluacionfinal'].hide();
                    this.$refs['frm-documentofinal'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        saveDocumentoFinalEvaluacion(){
            this.documentoFinalEvaluacion.Investigacion = this.investigacion.id;
            this.documentoFinalEvaluacion.DocumentoFinal = this.documentoFinal.id;
            this.documentoFinalEvaluacion.NotaEvaluacion = this.EFTotalCien;
            this.documentoFinalEvaluacion.ResultadoEvaluacion = this.EFResultado;
            if(this.EFTotalCien>=85){
                //aprobado
                this.documentoFinalEvaluacion.Estado = 3;
            }else{
                //observado
                this.documentoFinalEvaluacion.Estado = 2;
            }

            this.ss.storeDocumentoFinalEvaluacion(this.documentoFinalEvaluacion).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.listEvaluacionFinal();
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    //console.log(response);
                    this.modificarEstadoDocumentoFinal();
                    //this.listDocumentoFinalEvaluacion(this.documentoFinal);
                    this.$refs['frm-evaluacionfinal'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        editEvaluacionFinal(){
            this.$refs['frm-evaluacionfinal'].show();
            //this.showEvaluacionPropuesta(evaluacionPropuesta.id);
        },

        deleteEvaluacionFinal(evaluacionPropuesta){
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyEvaluacionPropuesta(evaluacionPropuesta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                //this.showInvestigacion(this.investigacion.id);
                                //this.$refs['datatable-seccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        cancelEvaluacionFinal(){
            this.$refs['frm-evaluacionfinal'].hide();
        },

        //
        getLineaInvestigacion(){
            let params = {
                AreaInvestigacion: this.investigacion.AreaInvestigacion
            };
            this.ss.listLineaInvestigacion(params).then(
                (result) => {
                    let response = result.data;
                    this.lineaInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAreaInvestigacion(){
            this.ss.listAreaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.areaInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCentroInvestigacion(){
            this.ss.listCentroInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.centroInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCarrera(){
            this.ss.listCarrera().then(
                (result) => {
                    let response = result.data;
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica(){
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadInvestigacion() {
            this.ss.listUnidadInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.unidadInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPrograma(){
            return this.ss.listPrograma()
            .then(result =>{
                if(result.data.success){
                    this.Programa = result.data.data;
                }else{
                    console.log("ocurrio un error");
                }
            })
            .catch(error =>{
                console.log(error);
            })
        },
        getTipoInvestigacion(){
            return this.ss.listTipoInvestigacion()
            .then(result =>{
                if(result.data.success){
                    this.TipoInvestigacion = result.data.data;
                }else{
                    console.log("ocurrio un error");
                }
            })
            .catch(error =>{
                console.log(error);
            })
        },

        loadFile(nombre) {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            if(nombre == "DocumentoFinal"){
                                this.documentoFinal.DocumentoFinal = result.data.data;
                            }
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFileInvestigacion() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.investigacion.ArchivoInvestigacion = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileInformeMensual() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.informeMensual.MedioVerificacion = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getEstado(){
            this.ss.listEstado().then(
                (result) => {
                    let response = result.data;
                    this.estados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        buscarPersona(search, loading){
            loading(true);
            this.ss.select2Persona(search).then((res) => {

                this.options = res.data.items;
                loading(false);
            })
        },
        actualizarElaboradoPersona(value){
            this.investigacion.ElaboradoPersona = value.id;
        },
        actualizarRevisadoPersona(value){
            this.investigacion.RevisadoPersona = value.id;
        },
        actualizarAprobadoPersona(value){
            this.investigacion.AprobadoPersona = value.id;
        },
        actualizarVistoBuenoPersona(value){
            this.investigacion.VistoBuenoPersona = value.id;
        },
        leerTabla(){
            let totalPorcentaje = 0;
            let valores = [];
            console.log("ejecuto");
            let inputs = document.getElementsByClassName('input_tabla');
            let checks = document.getElementsByClassName('checkbox_tabla');
            for(let i = 0; i<inputs.length; i++){
                if(checks[i].checked){
                    //valores[i+1] = inputs[i].value;
                    totalPorcentaje = totalPorcentaje +parseInt(inputs[i].value);
                }
                valores.push({mes: i+1, valor: checks[i].checked, porcentaje: inputs[i].value});
                console.log(i+1, inputs[i].value, checks[i].checked);
            }
            if(totalPorcentaje != 100){
                this.$swal({
                    title: "La planificacion de la actividad debe sumar 100% ",
                    text: "La suma del porcentaje es: "+totalPorcentaje,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return false;
            }
            console.log(totalPorcentaje);
            //if($totalPorcentaje != 100)

            this.tablaActividad = valores;
            this.actividad.Planificacion = JSON.stringify(valores);
            return true;
        },

        llenarTabla(){
            console.log("Llenar");
            let checks = document.getElementsByClassName('checkbox_tabla');
            let inputs = document.getElementsByClassName('input_tabla');

            for (let value of this.actividad.planificacionObj) {
                checks[value.mes-1].checked = value.valor;
                inputs[value.mes-1].style.display = (value.valor ? "block" : "none");
                inputs[value.mes-1].value=value.porcentaje;
                console.log(value);
            }
            // let planificacion = JSON.parse(this.actividad.Planificacion);
            // for (var [key, value] of Object.entries(planificacion)) {
            //     //checks[key-1].checked = true;
            //     checks[key-1].checked = value.valor;
            //     inputs[key-1].style.display = (value.valor ? "block" : "none");
            //     inputs[key-1].value=value.porcentaje;
            //     // inputs[key-1].value=value;
            //     //inputs[key-1].value=value.porcentaje;
            //     // console.log('Esta es la llave: '+key + ', este es el valor: ' + value); // "a 5", "b 7", "c 9"
            //     console.log("Holaa", key, value);
            // }
            


            // console.log("Llenar");
            // let checks = document.getElementsByClassName('checkbox_tabla');
            // let inputs = document.getElementsByClassName('input_tabla');
            // //let planificacion = JSON.parse(this.actividad.Planificacion);
            // for (var [key, value] of this.actividad.planificacionObj) {
            //     checks[key-1].checked = value.valor;
            //     inputs[key-1].style.display = (value.valor ? "block" : "none");
            //     inputs[key-1].value=value.porcentaje;
            //     console.log('Esta es la llave: '+key + ', este es el valor: ' + value); // "a 5", "b 7", "c 9"
            // }

        },

        toggleInput(num){
            
            let checkeado = false;
            let inputnum = {};
            let checks = document.getElementsByClassName('checkbox_tabla');
            let inputs = document.getElementsByClassName('input_tabla');
            for(let i = 0; i<inputs.length; i++){
                //console.log(checks[i].getAttribute('num'));
                if(checks[i].getAttribute('num') == num){
                    checkeado = checks[i].checked;
                }
                if(inputs[i].getAttribute('num') == num){
                    inputnum = inputs[i];
                    if(checkeado == false){
                        inputnum.value = '';
                        inputnum.style.display = 'none';
                    }else{
                        inputnum.style.display = 'block';
                    }
                    
                    //break;
                }
            }
            console.log(checkeado, inputnum);
        },
        listObjetivos(){
            
            let params = {
                Investigacion: this.investigacion.id
            }
            this.ss.listObjetivo(params).then(
                (result) => {
                    let response = result.data;
                    this.listaObjetivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        obtenerInformesPorMes(){
            let params = {
                id: this.investigacion.id
            }
            this.ss.obtenerInformesPorMes(params).then(
                (result) => {
                    let response = result.data;
                    this.mesesInvestigacion = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        reporteInvestigacion(){
            console.log("Generar reporte");
            
            this.ss.reporteInvestigacion(this.investigacion.id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    //this.forceFileDownload(response.data.url);
                    window.open(response.data.url);
                    
                }
            ).catch(error => {
                console.log(error);
                
            });
        },

        forceFileDownload(urlParam){
            const url = window.URL.createObjectURL(new Blob([urlParam]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'archivo.pdf') //or any other extension
            document.body.appendChild(link)
            link.click()
        },
        
    },
    watch: {
        query(q) {
            this.ss.select2Persona(q).then((res) => {
                this.users = res.data.items;
              })
        },

        queryInvestigacion(q) {
            this.ss.select2Investigacion(q).then((res) => {
                this.investigaciones = res.data.items;
              })
        },
        
    },
    components: {
        dataTable
    },
    computed: {
        //para la evaluacion de la propuesta
        totalResumen: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.ResumenProy1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResumenProy2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResumenProy3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResumenProy4) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResumenProy5) || 0);
            return suma;
        },
        totalAntecedentes: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.AntecedentesInv1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.AntecedentesInv2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.AntecedentesInv3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.AntecedentesInv4) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.AntecedentesInv5) || 0);
            return suma;
        },
        totalProblema: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.PlanteamientoProb1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.PlanteamientoProb2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.PlanteamientoProb3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.PlanteamientoProb4) || 0);

            return suma;
        },
        totalJustificacion: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionProy1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionProy2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionProy3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionProy4) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionProy5) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionProy6) || 0);

            return suma;
        },
        totalObjetivos: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.ObjetivosProy1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ObjetivosProy2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ObjetivosProy3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ObjetivosProy4) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ObjetivosProy5) || 0);

            return suma;
        },
        totalMetodologia: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.Metodologia1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.Metodologia2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.Metodologia3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.Metodologia4) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.Metodologia5) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.Metodologia6) || 0);

            return suma;
        },
        totalResultados: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.ResultadosEsp1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResultadosEsp2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResultadosEsp3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResultadosEsp4) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.ResultadosEsp5) || 0);

            return suma;
        },
        totalBibliografia: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.RevisionBiblio1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.RevisionBiblio2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.RevisionBiblio3) || 0);

            return suma;
        },
        totalRecursos: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.RecursosProy1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.RecursosProy2) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.RecursosProy3) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.RecursosProy4) || 0);

            return suma;
        },
        totalPresupuesto: function(){
            let suma = 0;
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionPres1) || 0);
            suma = suma + (parseInt(this.evaluacionPropuesta.JustificacionPres2) || 0);

            return suma;
        },
        totalEvaluacion: function(){
            let suma = this.totalPresupuesto+
                        this.totalRecursos+
                        this.totalBibliografia+
                        this.totalResultados+
                        this.totalMetodologia+
                        this.totalObjetivos+
                        this.totalJustificacion+
                        this.totalProblema+
                        this.totalAntecedentes+
                        this.totalResumen;
            return suma;
        },
        totalEvaluacionCien: function(){
            return Math.round((this.totalEvaluacion/225) * 100 * 10) / 10;
        },
        resultadoEvaluacion: function(){
            if(this.totalEvaluacionCien<=50){
                return "Reprobado"
            }else if(this.totalEvaluacionCien<=70){
                return "DEVUELTO - REFORMULACIÓN"
            }else if(this.totalEvaluacionCien<=85){
                return "APROBADO - CONDICIONADO"
            }else if(this.totalEvaluacionCien<=100){
                return "APROBADO"
            }
        },
        resultadoEvaluacionEstado: function(){
            if(this.totalEvaluacionCien<=50){
                return 2;
            }else if(this.totalEvaluacionCien<=70){
                return 3;
            }else if(this.totalEvaluacionCien<=85){
                return 4;
            }else if(this.totalEvaluacionCien<=100){
                return 5;
            }
        },
        //para la evaluacion del documento final
        EFTotalIntroduccion: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.IntroduccionProy1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.IntroduccionProy2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.IntroduccionProy3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.IntroduccionProy4) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.IntroduccionProy5) || 0);
            return suma;
        },
        EFTotalAntecedentes: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AntecedentesInv1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AntecedentesInv2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AntecedentesInv3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AntecedentesInv4) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AntecedentesInv5) || 0);
            return suma;
        },
        EFTotalProblema: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.PlanteamientoProb1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.PlanteamientoProb2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.PlanteamientoProb3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.PlanteamientoProb4) || 0);
            return suma;
        },
        EFTotalObjetivos: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ObjetivosProy1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ObjetivosProy2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ObjetivosProy3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ObjetivosProy4) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ObjetivosProy5) || 0);
            return suma;
        },
        EFTotalJustificacion: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.JustificacionProy1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.JustificacionProy2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.JustificacionProy3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.JustificacionProy4) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.JustificacionProy5) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.JustificacionProy6) || 0);
            return suma;
        },
        EFTotalAlcance: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AlcanceProy1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AlcanceProy2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AlcanceProy3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.AlcanceProy4) || 0);
            return suma;
        },
        EFTotalMarcoTeorico: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.MarcoTeorico1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.MarcoTeorico2) || 0);
            return suma;
        },
        EFTotalMetodologia: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Metodologia1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Metodologia2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Metodologia3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Metodologia4) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Metodologia5) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Metodologia6) || 0);
            return suma;
        },
        EFTotalDesarrollo: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.DesarrolloProy1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.DesarrolloProy2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.DesarrolloProy3) || 0);
            return suma;
        },
        EFTotalConclusiones: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ConclusionesRecom1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.ConclusionesRecom2) || 0);
            return suma;
        },
        EFTotalBibliografia: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.BibliografiaGlosario1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.BibliografiaGlosario2) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.BibliografiaGlosario3) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.BibliografiaGlosario4) || 0);
            return suma;
        },
        EFTotalAnexos: function(){
            let suma = 0;
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Anexos1) || 0);
            suma = suma + (parseInt(this.documentoFinalEvaluacion.Anexos2) || 0);
            return suma;
        },
        EFTotal: function(){
            let suma = this.EFTotalIntroduccion+
                        this.EFTotalAntecedentes+
                        this.EFTotalProblema+
                        this.EFTotalObjetivos+
                        this.EFTotalJustificacion+
                        this.EFTotalAlcance+
                        this.EFTotalMarcoTeorico+
                        this.EFTotalMetodologia+
                        this.EFTotalDesarrollo+
                        this.EFTotalConclusiones+
                        this.EFTotalBibliografia+
                        this.EFTotalAnexos;
            return suma;
        },
        EFTotalCien: function(){
            return Math.round((this.EFTotal/240) * 100 * 10) / 10;
        },
        EFResultado: function(){
            if(this.EFTotalCien<=50){
                return "Reprobado"
            }else if(this.EFTotalCien<=70){
                return "DEVUELTO - REFORMULACIÓN"
            }else if(this.EFTotalCien<=85){
                return "APROBADO - CONDICIONADO"
            }else if(this.EFTotalCien<=100){
                return "APROBADO"
            }
            
        },
        PorcentajeProyecto: function(){
            let suma = 0;
            this.listaObjetivos.forEach(element =>{
                suma = suma + (parseInt(element.Porcentaje) || 0)
            })
            return suma;
        },
        mostrarEvaluacionPropuesta: function(){
            //Si es un investigador
            
            if(this.persona.Rol == 6){
                if(this.investigacion.Estado>=2){
                    return true;
                }else{
                    return false;
                }
            }
            if(this.persona.Rol <= 5){
                return true;
            }
            return false;
        },
        mostrarAgregarEvaluacionPropuesta: function(){
            if(this.persona.Rol == 6){
                return false;
            }
            if(this.persona.Rol == 4 || this.persona.Rol == 2 || this.persona.Rol == 1){
                return true;
            }
            return false;
        },
        mostrarInformesMensuales: function(){
            if(this.persona.Rol == 6){
                if(this.investigacion.Estado>=2){
                    return true;
                }else{
                    return false;
                }
            }
            if(this.persona.Rol <= 5){
                return true;
            }
            return false;
        },
        mostrarAgregarInformeMensual: function(){
            if(this.persona.Rol == 6){
                return false;
            }
            if(this.persona.Rol == 4 || this.persona.Rol == 2 || this.persona.Rol == 1){
                return true;
            }
            return false;
        },
        mostrarAgregarEvaluacionInformeMensual: function(){
            if(this.persona.Rol == 6){
                return false;
            }
            if(this.persona.Rol == 4 || this.persona.Rol == 2 || this.persona.Rol == 1){
                return true;
            }
            return false;
        },
        mostrarDocumentoFinal:function(){
            if(this.persona.Rol == 6){
                if(this.investigacion.Estado>=3){
                    return true;
                }else{
                    return false;
                }
            }
            if(this.persona.Rol <= 5){
                return true;
            }
            return false;
        },
        mostrarAgregarDocumentoFinal: function(){
            if(this.persona.Rol == 6){
                return false;
            }
            if(this.persona.Rol == 4 || this.persona.Rol == 2 || this.persona.Rol == 1){
                return true;
            }
            return false;
        },
        mostrarAgregarEvaluacionDocumentoFinal: function(){
            if(this.persona.Rol == 6){
                return false;
            }
            if(this.persona.Rol == 4 || this.persona.Rol == 2 || this.persona.Rol == 1){
                return true;
            }
            return false;
        },
        editarInvestigacion:function(){
            if(this.persona.Rol == 6){
                if(this.investigacion.Estado>=2){
                    return false;
                }
                return false;
            }
            if(this.persona.Rol == 4 || this.persona.Rol == 2 ){
                return false;
            }

            if(this.persona.Rol == 1 ){
                return true;
            }
            return false;
        },
        totalPresupuestoA1:function(){
            let suma = 0;
            if(this.investigacion.presupuesto){
                this.investigacion.presupuesto.forEach(element => {
                    suma = suma + (parseFloat(element.A1) || 0);
                });
            }
            return suma;
        },
        totalPresupuestoA2:function(){
            let suma = 0;
            if(this.investigacion.presupuesto){
                this.investigacion.presupuesto.forEach(element => {
                    suma = suma + (parseFloat(element.A2) || 0);
                });
            }
            return suma;
        },
        totalPresupuestoA:function(){
            let suma = this.totalPresupuestoA1 + this.totalPresupuestoA2;
            return suma;
        },
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
        if (!this.persona) {
            this.$router.push('/Login');
        } else{
        this.getAreaInvestigacion();
        this.getLineaInvestigacion();
        this.getTipoInvestigacion();
        this.getCentroInvestigacion();
        this.getCarrera();
        this.getPrograma();
        this.getUnidadAcademica();
        this.getUnidadInvestigacion();
        this.getTipoInvestigacion();
        this.getEstado();
    }
  }
};

