<template>
    <div id="app">
        <v-app id="inspire">

            <v-app-bar
                app
                color="#003f8a"
                dark
                flat
                v-if="this.$router.currentRoute.name !== 'Login'"
            >
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                >
                </v-app-bar-nav-icon>
                <v-spacer></v-spacer>

                <v-btn icon>
                    <v-icon @click.prevent="cerrarSesion">mdi-logout</v-icon>
                </v-btn>
            </v-app-bar>

            <v-navigation-drawer
                color="white"
                v-model="drawer"
                :permanent="$vuetify.breakpoint.mdAndUp"
                app
                flat
                v-if="this.$router.currentRoute.name !== 'Login'"
            >
                <template v-slot:prepend>
                    <v-list-item>
                        <v-list-item-avatar>
                            <img :src="auth.URLFoto" />
                        </v-list-item-avatar>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content >
                            <v-list-item-title class="text-h6" style="white-space: normal;">
                                {{ auth.Persona }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="mt-2" style="white-space: normal;">
                                <i>{{auth.rol.Rol}}</i>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mt-2" style="white-space: normal;">
                                {{auth.unidad_academica.UnidadAcademica}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-divider></v-divider>
                <MenuVuetify />
            </v-navigation-drawer>
            <!-- Sizes your content based upon application components -->
            <v-main>
                <!-- If using vue-router -->

                <router-view></router-view>
            </v-main>
        </v-app>
    </div>
</template>

<script>
import MenuVuetify from "./partials/MenuVuetify.vue";


export default {
    name: "app",
    components: {
        MenuVuetify
        
    },
    data: () => ({
        drawer: false,
        auth: {
            rol: {},
            unidad_academica: {}
        }
    }),
    mounted() {
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if(!this.auth.rol){ //si no tiene la relacion rol... agregarle un objeto vacio por defecto
            this.auth.rol = {};
        }
        if(!this.auth.unidad_academica){ //si no tiene la relacion unidad academica... agregarle un objeto vacio por defecto
            this.auth.unidad_academica = {};
        }
    },
    methods: {
        cerrarSesion() {
            localStorage.clear();
            this.$router.push("/Login");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
			this.$router.go();
        },
    },
};
</script>


<style lang="scss">
@import "./assets/css/app.css";
@import "./assets/scss/app.scss";
</style> 