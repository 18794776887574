<template>
    <v-list nav dense>
        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4" >
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-sitemap</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Organizacion</v-list-item-title>
                </v-list-item-content>
            </template>
            
            <!--UnidadAcademica-->
            <router-link
                class="black--text text--accent-4 "
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-bank</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--NivelAcademico-->
            <router-link
                class="black--text text--accent-4"
                to="NivelAcademico"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-grid-large</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.NivelAcademico")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--Carrera-->
            <router-link
                class="black--text text--accent-4"
                to="Carrera"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-layers</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Carrera")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--CentroInvestigacion-->
            <router-link
                class="black--text text--accent-4"
                to="CentroInvestigacion"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-office-building</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.CentroInvestigacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--AreaInvestigacion-->
            <router-link
                class="black--text text--accent-4"
                to="AreaInvestigacion"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-home-search-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.AreaInvestigacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--LineaInvestigacion-->
            <router-link
                class="black--text text--accent-4"
                to="LineaInvestigacion"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-eyedropper</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.LineaInvestigacion") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            

            
            
            <!--Programa-->
            <router-link
                class="black--text text--accent-4"
                to="Programa"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-google-classroom</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Programa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

    <v-list-group  v-if="persona.Rol == 1">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cogs</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Configuracion</v-list-item-title>
                </v-list-item-content>
            </template>

            <!--Rol-->
            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--Estado-->
            <router-link
                class="black--text text--accent-4"
                to="Estado"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-alert-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Estado")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <!--TipoPersona-->
            <router-link
                class="black--text text--accent-4"
                to="TipoPersona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-account-details</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoPersona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            
            
        </v-list-group>

        <v-list-group  v-if="persona.Rol == 1">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>fab fa-hubspot</v-icon>
                    
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Relacionales</v-list-item-title>
                </v-list-item-content>
            </template>
            <!--InvestigacionPersona-->
            <router-link
                class="black--text text--accent-4"
                to="InvestigacionPersona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-clipboard-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.InvestigacionPersona") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!--InvestigacionCentroInvestigacion-->
            <router-link
                class="black--text text--accent-4"
                to="InvestigacionCentroInvestigacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-home-thermometer-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.InvestigacionCentroInvestigacion") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
         </v-list-group>

            <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>fas fa-flask</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Investigacion</v-list-item-title>
                </v-list-item-content>
            </template>
            <!--Investigacion-->
            <router-link
                class="black--text text--accent-4"
                to="Investigacion"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-feature-search</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.Investigacion") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!--InvestigacionBuscar-->
            <router-link
                class="black--text text--accent-4"
                to="InvestigacionBuscar"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-feature-search</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Buscar
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!--Actividad-->
            <router-link
                class="black--text text--accent-4"
                to="Actividad"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-clipboard-list-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.Actividad") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!--Objetivo-->
            <router-link
                class="black--text text--accent-4"
                to="Objetivo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.Objetivo") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!--Bibliografia-->
            <router-link
                class="black--text text--accent-4"
                to="Bibliografia"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.Bibliografia") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!--Informe Mensual-->
            <router-link
                class="black--text text--accent-4"
                to="InformeMensual"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item class="ml-6">
                    <v-list-item-icon>
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("nav.InformeMensual") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            </v-list-group>
            <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4 || persona.Rol == 5 || persona.Rol == 3"
            >
                <v-list-item >
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
                
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

        
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona: {},
        };
    },

    mounted() {
        this.persona = JSON.parse(localStorage.getItem("persona"));
    },
};
</script>

<style scoped>
.red_list .v-list-item-group .v-list-item--active{
  background-color: red;
  color: white;
}
</style>