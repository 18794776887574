import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InformeMensualPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InformeMensualPage",
            ss: ss,
            ajax: {
                "url": ss.indexInformeMensual(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Investigacion', name: 'Investigacion', title: 'Investigacion', orderable: true, searchable: true },
                { data: 'NumMes', name: 'NumMes', title: 'Informe del mes', orderable: true, searchable: true },
                { data: 'FechaInforme', name: 'FechaInforme', title: 'FechaInforme', orderable: true, searchable: true },
                { data: 'Estado', name: 'Estado', title: 'Estado', orderable: true, searchable: false },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            informeMensuals: [],
            informeMensual: {},
            informeMensualEvaluacion: {},
            informeMensualEvaluaciones: [],
            verInformeMensualEvaluacion: false,
            informeMensualEvaluacionFields: [ 'Estado', 'UsuarioEvaluador', 'CargoEvaluador', 'Acciones'],
            actividadesInforme: [],
            investigacion: {},
            listaObjetivos: [],
            unidadAcademicas: [],
            errorBag: {},
            filtros: {},
            persona: {},
            urlBackend: process.env.VUE_APP_MAIN_SERVICE,
        };
    },
    methods: {
        aplicarFiltros(){
            let filtrosLocal = this.filtros;
            this.ajax.data = function (d) {
                 d.UnidadAcademica = filtrosLocal.UnidadAcademica;
                 d.Estado = filtrosLocal.Estado;
            }
            this.$refs['datatable-informeMensual'].reloadAjax(this.ajax);
        },
        getUnidadAcademica(){
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newInformeMensual() {
            this.informeMensual = {};
            this.$refs['frm-informeMensual'].show();
        },
        showInformeMensual(id) {
            this.ss.showInformeMensual(id).then(
                (result) => {
                    let response = result.data;
                    this.informeMensual = response.data;
                    this.actividadesInforme = JSON.parse(this.informeMensual.ActividadesArray)
                    this.$refs['view-informeMensual'].show();
                    this.verEvaluacionesInformeMensual(this.informeMensual.id);
                    return this.informeMensual.Investigacion;
                }
            ).then( idInvestigacion =>{
                this.showInvestigacion(idInvestigacion);
            }).catch(error => {
                console.log(error);
            });
        },
        showInvestigacion(id) {
            this.ss.showInvestigacion(id).then(
                (result) => {
                    let response = result.data;
                    this.investigacion = response.data;
                    if (this.investigacion.persona) {
                        this.investigacion.personas.unshift(this.investigacion.persona);
                    }
                    this.listaObjetivos = this.investigacion.objetivo;

                    
                    // this.$refs['view-investigacion'].show();
                    // //recuperar los objetivos para dibujar el cronograma
                    // this.listObjetivos();
                    // this.listEvaluacionPropuesta();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        editInformeMensual() {
            this.$refs['frm-informeMensual'].show();
            this.$refs['view-informeMensual'].hide();
        },
        cancelInformeMensual() {
            if (this.informeMensual.id) {
                this.$refs['view-informeMensual'].show();
            }
            this.$refs['frm-informeMensual'].hide();
        },
        saveInformeMensual() {
            this.ss.storeInformeMensual(this.informeMensual).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-informeMensual'].hide();
                    this.$refs['datatable-informeMensual'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteInformeMensual() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyInformeMensual(this.informeMensual)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-informeMensual'].hide();
                                this.$refs['datatable-informeMensual'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        abrirObservacion(){
            this.$refs['view-observacion'].show();
            //this.$refs['view-informeMensual'].hide();
        },
        abrirAprobar(){
            this.$refs['view-aprobar'].show();
            //this.$refs['view-informeMensual'].hide();
        },
        observar(){
            this.$swal({
                title: "Estas seguro de realizar esta observacion?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.observarInformeMensual(this.informeMensual).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.informeMensual = response.data;
                                this.$refs['view-observacion'].hide();
                                //this.$refs['frm-informeMensual'].hide();
                                this.$refs['datatable-informeMensual'].reload();
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
            
        },
        aprobar(){
            this.$swal({
                title: "Estas seguro de aprobar este informe mensual?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarInformeMensual(this.informeMensual).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.informeMensual = response.data;
                                this.$refs['view-aprobar'].hide();
                                //this.$refs['frm-informeMensual'].hide();
                                this.$refs['datatable-informeMensual'].reload();
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        descargarStorage(nombreArchivo){
            if(!nombreArchivo){
                this.$swal({
                    title: "No se cargo ningun archivo",
                    text: "No se puede descargar",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            var urlFile = this.urlBackend+"storage/documents/"+nombreArchivo;
            window.open(urlFile);
            // var a = document.createElement("a");
            // a.href = urlFile;
            // a.setAttribute("download", nuevoNombre);
            // a.click();
        },
        verEvaluacionesInformeMensual(idInformeMensual){
            this.informeMensual.id = idInformeMensual;
            let params = {
                InformeMensual: this.informeMensual.id
            }
            this.ss.listInformeMensualEvaluacion(params).then(
                (result) => {
                    let response = result.data;
                    this.informeMensualEvaluaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            console.log(idInformeMensual);
        },
        newInformeMensualEvaluacion(){
            if (this.informeMensual.id) {
                this.informeMensualEvaluacion = {};
                this.$refs['frm-InformeMensualEvaluacion'].show();
            }else{
                this.$bvToast.toast(
                    'Seleccione un Objetivo para agregar Actividades',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            }
        },

        showInformeMensualEvaluacion(id){
            // this.$refs['frm-InformeMensualEvaluacion'].show();
            this.verInformeMensualEvaluacion = true;
            console.log("Abrir informe mensual", id)
            this.ss.showInformeMensualEvaluacion(id).then(
                (result) => {
                    let response = result.data;
                    this.informeMensualEvaluacion = response.data;
                    this.$refs['frm-InformeMensualEvaluacion'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        storeInformeMensualEvaluacion(estado){

            this.informeMensualEvaluacion.Investigacion = this.investigacion.id;
            this.informeMensualEvaluacion.InformeMensual = this.informeMensual.id;
            this.informeMensualEvaluacion.Estado = estado;
            this.informeMensualEvaluacion.CargoEvaluador = this.persona.rol.Rol;

            this.ss.storeInformeMensualEvaluacion(this.informeMensualEvaluacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-InformeMensualEvaluacion'].hide();
                    //this.showObjetivo(this.objetivo.id);
                    this.verEvaluacionesInformeMensual(this.informeMensual.id);
                    this.modificarEstadoInformeMensual();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        modificarEstadoInformeMensual(){
            let params = {
                InformeMensual: this.informeMensual.id
            }
            this.ss.modificarEstadoInformeMensual(params).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    //this.listInformeMensual();
                    //this.informemensual = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        draw() {
            window.$('.btn-datatable-InformeMensual').on('click', (evt) => {
                console.log("Mostrar informeMensual");
                const data = window.$(evt.target)[0].id;
                this.showInformeMensual(data);
            });
        }
    },
    components: {
        dataTable
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
        this.getUnidadAcademica();
        if (!this.persona) {
            this.$router.push('/Login');
        }
    }
};
