import Vue from 'vue'
import App from './App'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import i18n from "./i18n";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import vuetify from './plugins/vuetify'
 
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  vuetify,
  render: h => h(App),
  components: {
    VueBootstrapTypeahead
  }
})