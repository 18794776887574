var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    select2Persona(q) {
        return axios.get('api/Persona/select2?q=' + q);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    storeLogin(item) {
        return axios.post('api/Persona/storeLogin', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showUnidadInvestigacion(id) {
        return axios.get('api/UnidadInvestigacion/show?id=' + id);
    }

    indexUnidadInvestigacion() {
        return axios.defaults.baseURL + 'api/UnidadInvestigacion/index';
    }

    listUnidadInvestigacion() {
        return axios.get('api/UnidadInvestigacion/list');
    }

    storeUnidadInvestigacion(item) {
        return axios.post('api/UnidadInvestigacion/store', item);
    }

    destroyUnidadInvestigacion(item) {
        return axios.post('api/UnidadInvestigacion/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    showCarrera(id) {
        return axios.get('api/Carrera/show?id=' + id);
    }

    indexCarrera() {
        return axios.defaults.baseURL + 'api/Carrera/index';
    }

    listCarrera() {
        return axios.get('api/Carrera/list');
    }

    storeCarrera(item) {
        return axios.post('api/Carrera/store', item);
    }

    destroyCarrera(item) {
        return axios.post('api/Carrera/destroy', item);
    }

    showPrograma(id) {
        return axios.get('api/Programa/show?id=' + id);
    }

    indexPrograma() {
        return axios.defaults.baseURL + 'api/Programa/index';
    }

    listPrograma() {
        return axios.get('api/Programa/list');
    }

    storePrograma(item) {
        return axios.post('api/Programa/store', item);
    }

    destroyPrograma(item) {
        return axios.post('api/Programa/destroy', item);
    }

    showTipoPersona(id) {
        return axios.get('api/TipoPersona/show?id=' + id);
    }

    indexTipoPersona() {
        return axios.defaults.baseURL + 'api/TipoPersona/index';
    }

    listTipoPersona() {
        return axios.get('api/TipoPersona/list');
    }

    storeTipoPersona(item) {
        return axios.post('api/TipoPersona/store', item);
    }

    destroyTipoPersona(item) {
        return axios.post('api/TipoPersona/destroy', item);
    }

    showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico() {
        return axios.get('api/NivelAcademico/list');
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }
    
    showCentroInvestigacion(id) {
        return axios.get('api/CentroInvestigacion/show?id=' + id);
    }

    indexCentroInvestigacion() {
        return axios.defaults.baseURL + 'api/CentroInvestigacion/index';
    }

    listCentroInvestigacion() {
        return axios.get('api/CentroInvestigacion/list');
    }

    storeCentroInvestigacion(item) {
        return axios.post('api/CentroInvestigacion/store', item);
    }

    destroyCentroInvestigacion(item) {
        return axios.post('api/CentroInvestigacion/destroy', item);
    }

    select2CentroInvestigacion(q) {
        return axios.get('api/CentroInvestigacion/select2?q=' + q);
    }

    showEstado(id) {
        return axios.get('api/Estado/show?id=' + id);
    }

    indexEstado() {
        return axios.defaults.baseURL + 'api/Estado/index';
    }

    listEstado() {
        return axios.get('api/Estado/list');
    }

    storeEstado(item) {
        return axios.post('api/Estado/store', item);
    }

    destroyEstado(item) {
        return axios.post('api/Estado/destroy', item);
    }

    showAreaInvestigacion(id) {
        return axios.get('api/AreaInvestigacion/show?id=' + id);
    }

    indexAreaInvestigacion() {
        return axios.defaults.baseURL + 'api/AreaInvestigacion/index';
    }

    listAreaInvestigacion() {
        return axios.get('api/AreaInvestigacion/list');
    }

    storeAreaInvestigacion(item) {
        return axios.post('api/AreaInvestigacion/store', item);
    }

    destroyAreaInvestigacion(item) {
        return axios.post('api/AreaInvestigacion/destroy', item);
    }

    showTipoInvestigacion(id) {
        return axios.get('api/TipoInvestigacion/show?id=' + id);
    }

    indexTipoInvestigacion() {
        return axios.defaults.baseURL + 'api/TipoInvestigacion/index';
    }

    listTipoInvestigacion() {
        return axios.get('api/TipoInvestigacion/list');
    }

    storeTipoInvestigacion(item) {
        return axios.post('api/TipoInvestigacion/store', item);
    }

    destroyTipoInvestigacion(item) {
        return axios.post('api/TipoInvestigacion/destroy', item);
    }

    showLineaInvestigacion(id) {
        return axios.get('api/LineaInvestigacion/show?id=' + id);
    }

    indexLineaInvestigacion() {
        return axios.defaults.baseURL + 'api/LineaInvestigacion/index';
    }

    listLineaInvestigacion(params) {
        return axios.get('api/LineaInvestigacion/list', {params: params});
    }

    storeLineaInvestigacion(item) {
        return axios.post('api/LineaInvestigacion/store', item);
    }

    destroyLineaInvestigacion(item) {
        return axios.post('api/LineaInvestigacion/destroy', item);
    }

    //////////////////////////////////INVESTIGACION//////////////////////////////////////////
    showInvestigacion(id) {
        return axios.get('api/Investigacion/show?id=' + id);
    }

    indexInvestigacion() {
        return axios.defaults.baseURL + 'api/Investigacion/index';
    }

    indexInvestigacionBuscar() {
        return axios.defaults.baseURL + 'api/Investigacion/indexBuscar';
    }

    listInvestigacion() {
        return axios.get('api/Investigacion/list');
    }

    storeInvestigacion(item) {
        return axios.post('api/Investigacion/store', item);
    }

    destroyInvestigacion(item) {
        return axios.post('api/Investigacion/destroy', item);
    }

    enviarPropuestaInvestigacion(item) {
        return axios.post('api/Investigacion/enviarPropuesta', item);
    }

    aprobarInvestigacion(item) {
        return axios.post('api/Investigacion/aprobar', item);
    }

    verificarEvaluacionPropuestaInvestigacion(item) {
        return axios.post('api/Investigacion/verificarEvaluacionPropuesta', item);
    }

    revisarJCInvestigacion(item) {
        return axios.post('api/Investigacion/revisarJC', item);
    }

    revisarInvestigacion(item) {
        return axios.post('api/Investigacion/revisar', item);
    }

    vistoBuenoInvestigacion(item) {
        return axios.post('api/Investigacion/vistoBueno', item);
    }

    finalizarInvestigacion(item) {
        return axios.post('api/Investigacion/finalizar', item);
    }

    select2Investigacion(q) {
        return axios.get('api/Investigacion/select2?q=' + q);
    }

    obtenerInformesPorMes(params) {
        return axios.get('api/Investigacion/obtenerInformesPorMes', {params: params});
    }

    //////////////////////////////////INVESTIGACIONPERSONA//////////////////////////////////////////
    showInvestigacionPersona(id) {
        return axios.get('api/InvestigacionPersona/show?id=' + id);
    }

    indexInvestigacionPersona() {
        return axios.defaults.baseURL + 'api/InvestigacionPersona/index';
    }

    listInvestigacionPersona() {
        return axios.get('api/InvestigacionPersona/list');
    }

    storeInvestigacionPersona(item) {
        return axios.post('api/InvestigacionPersona/store', item);
    }

    destroyInvestigacionPersona(item) {
        return axios.post('api/InvestigacionPersona/destroy', item);
    }
    

    //////////////////////////////////INVESTIGACIONCENTROINVESTIGACION//////////////////////////////////////////
    showInvestigacionCentroInvestigacion(id) {
        return axios.get('api/InvestigacionCentroInvestigacion/show?id=' + id);
    }

    indexInvestigacionCentroInvestigacion() {
        return axios.defaults.baseURL + 'api/InvestigacionCentroInvestigacion/index';
    }

    listInvestigacionCentroInvestigacion() {
        return axios.get('api/InvestigacionCentroInvestigacion/list');
    }

    storeInvestigacionCentroInvestigacion(item) {
        return axios.post('api/InvestigacionCentroInvestigacion/store', item);
    }

    destroyInvestigacionCentroInvestigacion(item) {
        return axios.post('api/InvestigacionCentroInvestigacion/destroy', item);
    }
    //////////////////////////////////Actividad//////////////////////////////////////////
    showActividad(id) {
        return axios.get('api/Actividad/show?id=' + id);
    }

    indexActividad() {
        return axios.defaults.baseURL + 'api/Actividad/index';
    }

    listActividad() {
        return axios.get('api/Actividad/list');
    }

    storeActividad(item) {
        return axios.post('api/Actividad/store', item);
    }

    destroyActividad(item) {
        return axios.post('api/Actividad/destroy', item);
    }

    obtenerActividadesMes(item) {
        return axios.post('api/Actividad/obtenerActividadesMes', item);
    }
    //////////////////////////////////Objetivo//////////////////////////////////////////
    showObjetivo(id) {
        return axios.get('api/Objetivo/show?id=' + id);
    }

    indexObjetivo() {
        return axios.defaults.baseURL + 'api/Objetivo/index';
    }

    listObjetivo(params) {
        return axios.get('api/Objetivo/list', {params: params});
    }

    storeObjetivo(item) {
        return axios.post('api/Objetivo/store', item);
    }

    destroyObjetivo(item) {
        return axios.post('api/Objetivo/destroy', item);
    }

    //////////////////////////////////Material//////////////////////////////////////////
    showMaterial(id) {
        return axios.get('api/Material/show?id=' + id);
    }

    indexMaterial() {
        return axios.defaults.baseURL + 'api/Material/index';
    }

    listMaterial(params) {
        return axios.get('api/Material/list', {params: params});
    }

    storeMaterial(item) {
        return axios.post('api/Material/store', item);
    }

    destroyMaterial(item) {
        return axios.post('api/Material/destroy', item);
    }

    //////////////////////////////////Informe Mensual//////////////////////////////////////////
    showInformeMensual(id) {
        return axios.get('api/InformeMensual/show?id=' + id);
    }

    indexInformeMensual() {
        return axios.defaults.baseURL + 'api/InformeMensual/index';
    }

    listInformeMensual(params) {
        return axios.get('api/InformeMensual/list', {params: params});
    }

    storeInformeMensual(item) {
        return axios.post('api/InformeMensual/store', item);
    }

    destroyInformeMensual(item) {
        return axios.post('api/InformeMensual/destroy', item);
    }

    observarInformeMensual(item) {
        return axios.post('api/InformeMensual/observar', item);
    }

    aprobarInformeMensual(item) {
        return axios.post('api/InformeMensual/aprobar', item);
    }

    modificarEstadoInformeMensual(item) {
        return axios.post('api/InformeMensual/modificarEstado', item);
    }

    //////////////////////////////////Informe Mensual Evaluacion//////////////////////////////////////////
    showInformeMensualEvaluacion(id) {
        return axios.get('api/InformeMensualEvaluacion/show?id=' + id);
    }

    // indexInformeMensualEvaluacion() {
    //     return axios.defaults.baseURL + 'api/InformeMensualEvaluacion/index';
    // }

    listInformeMensualEvaluacion(params) {
        return axios.get('api/InformeMensualEvaluacion/list', {params: params});
    }

    storeInformeMensualEvaluacion(item) {
        return axios.post('api/InformeMensualEvaluacion/store', item);
    }

    destroyInformeMensualEvaluacion(item) {
        return axios.post('api/InformeMensualEvaluacion/destroy', item);
    }

    
    //////////////////////////////////Bibliografia//////////////////////////////////////////
    showBibliografia(id) {
        return axios.get('api/Bibliografia/show?id=' + id);
    }

    indexBibliografia() {
        return axios.defaults.baseURL + 'api/Bibliografia/index';
    }

    listBibliografia() {
        return axios.get('api/Bibliografia/list');
    }

    storeBibliografia(item) {
        return axios.post('api/Bibliografia/store', item);
    }

    destroyBibliografia(item) {
        return axios.post('api/Bibliografia/destroy', item);
    }

    //////////////////////////////////Presupuesto//////////////////////////////////////////
    showPresupuesto(id) {
        return axios.get('api/Presupuesto/show?id=' + id);
    }

    listPresupuesto(params) {
        return axios.get('api/Presupuesto/list', {params: params});
    }

    storePresupuesto(item) {
        return axios.post('api/Presupuesto/store', item);
    }

    destroyPresupuesto(item) {
        return axios.post('api/Presupuesto/destroy', item);
    }

    //////////////////////////////////Financiamiento//////////////////////////////////////////
    showFinanciamiento(id) {
        return axios.get('api/Financiamiento/show?id=' + id);
    }

    listFinanciamiento(params) {
        return axios.get('api/Financiamiento/list', {params: params});
    }

    storeFinanciamiento(item) {
        return axios.post('api/Financiamiento/store', item);
    }

    destroyFinanciamiento(item) {
        return axios.post('api/Financiamiento/destroy', item);
    }

    //////////////////////////////////EvaluacionPropuesta//////////////////////////////////////////
    showEvaluacionPropuesta(id) {
        return axios.get('api/EvaluacionPropuesta/show?id=' + id);
    }

    listEvaluacionPropuesta(params) {
        return axios.get('api/EvaluacionPropuesta/list', {params: params});
    }

    storeEvaluacionPropuesta(item) {
        return axios.post('api/EvaluacionPropuesta/store', item);
    }

    destroyEvaluacionPropuesta(item) {
        return axios.post('api/EvaluacionPropuesta/destroy', item);
    }

    //////////////////////////////////DocumentoFinal//////////////////////////////////////////
    showDocumentoFinal(id) {
        return axios.get('api/DocumentoFinal/show?id=' + id);
    }

    listDocumentoFinal(params) {
        return axios.get('api/DocumentoFinal/list', {params: params});
    }

    storeDocumentoFinal(item) {
        return axios.post('api/DocumentoFinal/store', item);
    }

    destroyDocumentoFinal(item) {
        return axios.post('api/DocumentoFinal/destroy', item);
    }

    modificarEstadoDocumentoFinal(item) {
        return axios.post('api/DocumentoFinal/modificarEstado', item);
    }

    //////////////////////////////////DocumentoFinalEvaluacion//////////////////////////////////////////
    showDocumentoFinalEvaluacion(id) {
        return axios.get('api/DocumentoFinalEvaluacion/show?id=' + id);
    }

    listDocumentoFinalEvaluacion(params) {
        return axios.get('api/DocumentoFinalEvaluacion/list', {params: params});
    }

    storeDocumentoFinalEvaluacion(item) {
        return axios.post('api/DocumentoFinalEvaluacion/storeDocumentoFinalEvaluacion', item);
    }

    destroyDocumentoFinalEvaluacion(item) {
        return axios.post('api/DocumentoFinalEvaluacion/destroy', item);
    }

    //////////////////////////////////TipoReporte//////////////////////////////////////////
    reporteInvestigacion(id) {
        return axios.get('api/TipoReporte/generate?TipoReporte=3&idInvestigacion=' + id);
    }

}