import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import UnidadInvestigacionPage from '@/pages/UnidadInvestigacion/UnidadInvestigacionPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import TipoPersonaPage from '@/pages/TipoPersona/TipoPersonaPage.vue'
import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'
import CarreraPage from '@/pages/Carrera/CarreraPage.vue'
import ProgramaPage from '@/pages/Programa/ProgramaPage.vue'
import CentroInvestigacionPage from '@/pages/CentroInvestigacion/CentroInvestigacionPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import EstadoPage from '@/pages/Estado/EstadoPage.vue'
import AreaInvestigacionPage from '@/pages/AreaInvestigacion/AreaInvestigacionPage.vue'
import LineaInvestigacionPage from '@/pages/LineaInvestigacion/LineaInvestigacionPage.vue'
import TipoInvestigacionPage from '@/pages/TipoInvestigacion/TipoInvestigacionPage.vue'
import InvestigacionPersonaPage from '@/pages/InvestigacionPersona/InvestigacionPersonaPage.vue'
import InvestigacionCentroInvestigacionPage from '@/pages/InvestigacionCentroInvestigacion/InvestigacionCentroInvestigacionPage.vue'
import InvestigacionPage from '@/pages/Investigacion/InvestigacionPage.vue'
import InvestigacionBuscarPage from '@/pages/InvestigacionBuscar/InvestigacionBuscarPage.vue'
import ActividadPage from '@/pages/Actividad/ActividadPage.vue'
import ObjetivoPage from '@/pages/Objetivo/ObjetivoPage.vue'
import BibliografiaPage from '@/pages/Bibliografia/BibliografiaPage.vue'
import InformeMensualPage from '@/pages/InformeMensual/InformeMensualPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/UnidadInvestigacion',
      name: 'Unidad de Investigacion',
      component: UnidadInvestigacionPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/TipoPersona',
      name: 'TipoPersona',
      component: TipoPersonaPage
    },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    {
      path: '/Carrera',
      name: 'Carrera',
      component: CarreraPage
    },
    {
      path: '/Programa',
      name: 'Programa',
      component: ProgramaPage
    },
    {
      path: '/CentroInvestigacion',
      name: 'CentroInvestigacion',
      component: CentroInvestigacionPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/Estado',
      name: 'Estado',
      component: EstadoPage
    },
    {
      path: '/AreaInvestigacion',
      name: 'Area de Investigacion',
      component: AreaInvestigacionPage
    },
    {
      path: '/LineaInvestigacion',
      name: 'Linea de Investigacion',
      component: LineaInvestigacionPage
    },
    {
      path: '/TipoInvestigacion',
      name: 'Tipo de Investigacion',
      component: TipoInvestigacionPage
    },
    {
      path: '/InvestigacionPersona',
      name: 'Investigacion Persona',
      component: InvestigacionPersonaPage
    },
    {
      path: '/InvestigacionCentroInvestigacion',
      name: 'Investigacion Centro Investigacion',
      component: InvestigacionCentroInvestigacionPage
    },
    {
      path: '/Investigacion',
      name: 'Investigacion',
      component: InvestigacionPage
    },
    {
      path: '/InvestigacionBuscar',
      name: 'InvestigacionBuscar',
      component: InvestigacionBuscarPage
    },
    {
      path: '/Actividad',
      name: 'Actividad',
      component: ActividadPage
    },
    {
      path: '/Objetivo',
      name: 'Objetivo',
      component: ObjetivoPage
    },
    {
      path: '/Bibliografia',
      name: 'Bibliografia',
      component: BibliografiaPage
    },
    {
      path: '/InformeMensual',
      name: 'InformeMensual',
      component: InformeMensualPage
    },
    
  ]
})