import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ActividadPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ActividadPage",
            ss: ss,
            ajax: {
                "url": ss.indexActividad(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Actividad', name: 'Actividad', title: 'Actividad' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripcion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            actividads: [],
            actividad: {},
            objetivos:[],
            investigacions:[],
            isLoading: false,
            errorBag: {},
            isLoadingFile:false,
           /*  items: [
                {
                    'id':'1',
                    'title': 'hello',
                    'description': 'ok ok',
                    'created_date': '2018-09-09'
                },
                {
                    'id':'2',
                    'title': 'hello 2',
                    'description': 'ok ok 2',
                    'created_date': '2018-10-09'
                }
            ],
            fields: [ 'id', 'title', 'description', 'created_date'] */
        };
    },
    methods: {
        newActividad() {
            this.actividad = {};
            this.$refs['frm-actividad'].show();
        },
        showActividad(id) {
            this.isLoading=true;
            this.ss.showActividad(id).then(
                (result) => {
                    let response = result.data;
                    this.actividad = response.data;
                    this.$refs['view-actividad'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editActividad() {
            this.$refs['frm-actividad'].show();
            this.$refs['view-actividad'].hide();
        },
        cancelActividad() {
            if (this.actividad.id) {
                this.$refs['view-actividad'].show();
            }
            this.$refs['frm-actividad'].hide();
        },
        saveActividad() {
            this.ss.storeActividad(this.actividad).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-actividad'].hide();
                    this.$refs['datatable-actividad'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteActividad() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyActividad(this.actividad)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-actividad'].hide();
                                this.$refs['datatable-actividad'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getObjetivo() {
            let params = {};
            this.ss.listObjetivo(params).then(
                (result) => {
                    let response = result.data;
                    this.objetivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getInvestigacion() {
            this.ss.listInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.investigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-Actividad').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showActividad(data);
            });
        }
    },
    
    loadFileActividad() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.actividad.ArchivoActividad = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getObjetivo();
      }
    }
};
